import React, { useState, useEffect } from "react"
import { useAuthUser } from "react-auth-kit"

function Stats() {
	const auth = useAuthUser()
	const user = auth()

	const [onboards, setOnboards] = useState(0)
	const [engagement, setEngagement] = useState(0)
	const [attendance, setAttandance] = useState(0)
	const [lastEngagement, setLastEngagement] = useState(null)

	const currentDay = String(new Date().getDate()).padStart(2, "0")
	const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0")
	const currentYear = new Date().getFullYear()

	const getDaysInMonth = (month, year) => {
		return new Date(year, month, 0).getDate()
	}

	const onboards_url = `${process.env.REACT_APP_BASE_URL}/api/v2/che-tool/statistics?startDate=${currentYear}-${currentMonth}-${currentDay}&groupByType=day&endDate=${currentYear}-${currentMonth}-${currentDay}&statisticType=onboarding`
	const engagement_url = `${
		process.env.REACT_APP_BASE_URL
	}/api/v2/che-tool/statistics?startDate=${currentYear}-${currentMonth}-01&groupByType=month&statisticType=engagement&endDate=${currentYear}-${currentMonth}-${getDaysInMonth(
		currentMonth,
		currentYear
	)}`
	const attendance_url = `${
		process.env.REACT_APP_BASE_URL
	}/api/v2/che-tool/statistics?startDate=${currentYear}-${currentMonth}-01&groupByType=month&statisticType=attendance&endDate=${currentYear}-${currentMonth}-${getDaysInMonth(
		currentMonth,
		currentYear
	)}`

	useEffect(() => {
		fetch(onboards_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				const sum = result.reduce((a, b) => a + b.total, 0)
				setOnboards(sum)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [onboards_url, user.apikey])
	useEffect(() => {
		fetch(engagement_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				const sum = result.reduce((a, b) => a + b.total, 0)
				if (result[result.length - 1]) {
					setLastEngagement(result[result.length - 1].label)
				} else {
					setLastEngagement(null)
				}
				setEngagement(sum)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [engagement_url, user.apikey])
	useEffect(() => {
		fetch(attendance_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				const sum = result.reduce((a, b) => a + b.total, 0)
				setAttandance(sum)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [attendance_url, user.apikey])

	return (
		<div className="bg-white p-[19px] grid gap-[14px] rounded-[2px] sm:mt-[19px] mt-[10px] w-full sm:order-3">
			<div className="flex">
				<div className="text-[17px] font-medium">Performance Stats</div>
			</div>
			<ul className="grid sm:grid-rows-3 md:grid-cols-3 gap-[16px]">
				<li className="flex flex-col border-[#fff5e8] border-[1px] p-[10px] rounded-[2px]">
					<div className="text-[13px] font-medium">
						Onboarded Users
					</div>
					<div className="text-[20px] font-bold mt-[4px]">
						<span className="text-[#f19b3e]">{onboards}</span>
						/10
					</div>
					<div className="text-[12px] font-light mt-[2px]">
						<span className="text-[#f19b3e] font-medium">
							{`${(onboards / 10) * 100}%`}
						</span>{" "}
						Complete today
					</div>
				</li>
				<li className="flex flex-col border-[#fff5e8] border-[1px] p-[10px] rounded-[2px]">
					<div className="text-[13px] font-medium">Engagements</div>
					<div className="text-[20px] font-bold mt-[4px]">
						{engagement}
					</div>
					{lastEngagement && (
						<div className="text-[12px] font-light mt-[2px] flex gap-[5px]">
							Last update{" "}
							<span className="text-[#f19b3e] font-medium">
								{new Date(lastEngagement).toLocaleDateString(
									"en-US",
									{
										year: "numeric",
										month: "short",
										day: "2-digit",
									}
								)}
							</span>
						</div>
					)}
					{!lastEngagement && (
						<div className="text-[12px] font-light mt-[2px] flex gap-[5px]">
							Last update{" "}
							<span className="text-[#f19b3e] font-medium">
								None
							</span>
						</div>
					)}
				</li>
				<li className="flex flex-col border-[#fff5e8] border-[1px] p-[10px] rounded-[2px]">
					<div className="text-[13px] font-medium">Attendance</div>
					<div className="text-[20px] font-bold mt-[4px]">
						<span className="text-[#f19b3e]">{attendance}</span>/26
					</div>
					<div className="text-[12px] font-light mt-[2px] flex gap-[5px]">
						<span className="text-[#F19B3E] font-medium">{`${(
							(attendance / 26) *
							100
						).toFixed(2)}%`}</span>
						attendance this month
					</div>
				</li>
			</ul>
		</div>
	)
}

export default Stats
